import Vue from 'vue';
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import Antd from 'ant-design-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'ant-design-vue/dist/antd.css';
import Vuex from 'vuex';
import VueGoodTablePlugin from 'vue-good-table';
import VueHorizontal from 'vue-horizontal';
// import the styles
import 'vue-good-table/dist/vue-good-table.css';
import VueApexCharts from 'vue-apexcharts';
import L from 'leaflet';
import Filter from './assets/js/filter';
import router from './router';
import App from './App.vue';
// import Gleap from 'gleap';
// Gleap.initialize("QUfr6IJzbOCRBuXb2sRb0klZQA6yNsV1");
import { authMode, domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from './auth/auth0-plugin.js';
import { CognitoPlugin } from './auth/cognito-plugin.js';
import { setupCognito } from './auth/cognito-setup.js';
import { getLegendObject } from './assets/js/legend.js';

if (authMode == "COGNITO") {
  setupCognito()
  Vue.use(CognitoPlugin);
} else {
  Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      );
    },
  });
}

Vue.config.productionTip = false;


Vue.component(VueHorizontal);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);
Vue.use(VueGoodTablePlugin);

Vue.config.productionTip = false;
Vue.use(Antd);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
Vue.use(Vuex);

const endDate = new Date();
const newendDate = endDate.toISOString().slice(0, 10)

const startDate = new Date();
startDate.setMonth(endDate.getMonth() - 1);
const newstartDate = startDate.toISOString().slice(0, 10)

//Nach Datenbank Update hier das neue endDate eintragen. Das endDate ist das aktuelleste Datum aus der ACLED Datenbank.
//Als startDate einen Monat vor endDate eintragen
let filterState = new Filter(newstartDate, newendDate, ['Alle'], ['Alle'],['Alle'], ['Alle'],  L.latLngBounds([0, 0], [0, 0]));

const DEFAULT_TITLE = 'Demonstrator';

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

const store = new Vuex.Store({
  state: {
    progress: 100,
    db: 0,
    freeze: true,
    filter: filterState,
    gridData: [],
    legend: {
      'main': getLegendObject('markerCluster'),  // Let's try to handle the legend via the Vuex state
      'sub': [],
      'agg': null
    }
  },
  mutations: {
    setSelectedLayer(state, layer) {
      state.selectedLayer = layer;
    },
    enableLegend(state) {
      state.legend.main.active = true
    },
    disableLegend(state) {
      state.legend.main.active = false
    },
    addSubLegend(state, legend) {
      if (!state.legend.sub.find((e) => e.id == legend.id)) {
        state.legend.sub.push(legend)
      }
    },
    removeSubLegend(state, legendKey) {
      state.legend.sub = state.legend.sub.filter((e) => e.id != legendKey)
    },
    addAggLegend(state, legend) {
      state.legend.agg = legend
    },
    removeAggLegend(state) {
      state.legend.agg = null
    },
    setGridData(state, gridData) {
      state.gridData = gridData;
    },
    removeGridData(state) {
      state.gridData = null;
    },
    setFreeze(state, freeze) {
      state.freeze = freeze;
    },
    setDB(state, db) {
      state.db = db;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setProgress(state, progress) {
      state.progress = progress;
    },
    setStartDate(state, date) {
      state.filter.startDate = date;
    },
    setEndDate(state, date) {
      state.filter.endDate = date;
    },
    setEvent(state, event) {
      state.filter.event = event;
    },
    setInteraction(state, interaction) {
      state.filter.interaction = interaction;
    },
    setBounds(state, bounds) {
      state.filter.bounds = bounds;
    },
    setCountry(state, country) {
      state.filter.country = country;
    },
    setActor1(state, actor1) {
      state.filter.actor1 = actor1;
    },
    setActor2(state, actor2) {
      state.filter.actor2 = actor2;
    },
    setDeaths(state, deaths) {
      state.filter.deaths = deaths;
    },
    setSearch(state, search) {
      state.filter.search = search;
    },
    setSort(state, sort) {
      state.filter.sort = sort;
    },
    setPage(state, page) {
      state.filter.page = page;
    },
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
