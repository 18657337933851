import { Amplify } from 'aws-amplify';
import { Amplify as AmplifyCore } from '@aws-amplify/core';
import { defaultStorage } from 'aws-amplify/utils';

import {
	cognitoCredentialsProvider,
	cognitoUserPoolsTokenProvider,
} from 'aws-amplify/auth/cognito';

const getCognitoConfig = () => {
    const mode = process.env.NODE_ENV
    if (mode == "development") {
        try {
            const { config } =require('./cognito.config.js')
            return config
        } catch (error) {
            console.log(`Cognito configuration for the "${mode}" environment is missing. `, error)
        }
        return config
    } else if (mode == "production") {
        try {
            const {config} = require('./cognito.config.production.js')
            return config
        } catch (error) {
            console.log(`Cognito configuration for the "${mode}" environment is missing. `, error)
        }
    }   
}

const config = getCognitoConfig()

// As we are hotfixing webpack 4 and babel to allow the use of optional chaining etc.
// some stuff seems to be bundled in a weird way requiring an import and configuration
// of Amplify both from aws-amplify and @aws-amplify/core as different subfolders
// TODO: fix this by changing the babel settings further
export const setupCognito = () => {
 
    cognitoUserPoolsTokenProvider.setAuthConfig(config.Auth);
    cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

    Amplify.configure(config, { Auth: {
        tokenProvider: cognitoUserPoolsTokenProvider,
        credentialsProvider: cognitoCredentialsProvider
    }})
    AmplifyCore.configure(config )

}

