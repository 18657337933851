export const config = {
    Auth: {
        Cognito: {
            userPoolClientId: '5kcs990u4l55340ivvt869gckf',
            userPoolId: 'eu-central-1_zZfgW9C5X',
            loginWith: { // Optional
                oauth: {
                    domain: 'auth-kompzhub.auth.eu-central-1.amazoncognito.com',
                    scopes: ['openid','aws.cognito.signin.user.admin'],
                    redirectSignIn: ['https://www.kfe-demonstrator.com'],
                    redirectSignOut: ['https://www.kfe-demonstrator.com'],
                    responseType: 'code',
                },
                username: 'true',
                email: 'true', // Optional
                phone: 'false', // Optional
            }
        }
    }  
  }