export default class Filter {
  constructor(startDate, endDate, event, interaction, actor1, actor2, bounds) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.event = event;
    this.interaction = interaction;
    this.actor1 = actor1;
    this.actor2 = actor2;
    this.bounds = bounds;
    this.search = '';
    this.sort = {
      col: '',
      ord: '',
    };
    this.country = ['Welt'];
    this.deaths = [];
    this.page = 1;
  }
}
